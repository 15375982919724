<template>
  <div class="modal fade" id="new-card-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">New Card</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="card-element"></div>
          <span class="text-danger" v-if="cardError.show">{{ cardError.message }}</span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="addCard">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      cardError: {
        message: null,
        show: false
      },
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('new-card-modal'))
  },
  methods: {
    show() {
      this.modal.show();
      this.initStripe();
    },
    hide() {
      this.modal.hide();
    },
    async addCard() {
      this.cardError.show = false;
      this.loading = true;

      const data = await this.stripeInit.createPaymentMethod(
        'card', this.paymentForm
      );

      if (data.error) {
        this.cardError.message = data.error.message
        this.cardError.show = true
        this.loading = false;
      } else {
        this.cardError.message = null
        this.cardError.show = false
      }

      if (!data.error) {
        this.axios.post('/update-payment', {
          paymentId: data.paymentMethod.id
        }).then(() => {
          this.hide();
          this.$store.dispatch('auth/getPayment')
        }).finally(() => this.loading = false);
      }
    },
    initStripe() {
      this.stripeInit = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      const elements = this.stripeInit.elements();
      this.paymentForm = elements.create('card');
      this.paymentForm.mount('#card-element');
    },
  }
}
</script>