<template>
  <page-title-component title="Invoices"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3" v-if="payment">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <div class="alert alert-danger" v-if="payment && payment.has_due">Please pay your due invoices</div>

                  <div class="row">
                    <div class="col-8">
                      <div class="alert alert-info" v-if="!payment.default_payment_method">
                        No default payment method.
                      </div>
                      <div v-if="payment.default_payment_method && payment.default_payment_method.type === 'card'">
                        <img style="height: 50px"
                             :src="'/images/'+payment.default_payment_method.brand+'.png'"> **** **** **** {{ payment.default_payment_method.last4 }}
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <button class="btn btn-primary btn-sm"
                              @click="$refs.newCardModal.show()">Change</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-info btn-sm"
                      @click="mergeInvoices"
                      :disabled="!selectedInvoices.length || loadingMerge">Pay Selected Invoices</button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <table class="table table-hover table-striped table-bordered">
                <thead>
                <tr>
                  <th></th>
                  <th>Invoice Number</th>
                  <th>Created</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="invoice in invoices" :key="'invoice_'+invoice.id">
                  <td>
                    <input v-if="!invoice.paid"
                           :value="invoice.id"
                           v-model="selectedInvoices"
                           type="checkbox">
                  </td>
                  <td>{{ invoice.number }}</td>
                  <td>{{ invoice.created }}</td>
                  <td>${{ invoice.amount }}</td>
                  <td>
                    <span class="badge badge-success" v-if="invoice.paid">Paid</span>
                    <span class="badge badge-danger" v-else>Unpaid</span>
                  </td>
                  <td class="text-right">
                    <a :href="invoice.payment_url"
                       target="_blank"
                       v-if="!invoice.paid"
                       class="btn btn-sm btn-success mr-2">Pay</a>

                    <a :href="invoice.pdf_url"
                       target="_blank"
                       class="btn btn-sm btn-info">Invoice</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <new-card-modal ref="newCardModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import {mapGetters} from "vuex";
import NewCardModal from "@/components/NewCardModal.vue";
export default {
  components: {PageTitleComponent, NewCardModal},
  data() {
    return {
      invoices: [],
      loadingMerge: false,
      selectedInvoices: []
    }
  },
  computed: {
    ...mapGetters({
      payment: 'auth/getPayment'
    })
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.invoices = [];

      this.axios.get('/invoices')
        .then((response) => {
          this.invoices = response.data.data;
        })
    },
    mergeInvoices() {
      this.loadingMerge = true;

      this.axios.post('/merge-invoices', {
          ids: this.selectedInvoices
        })
        .then(res => {
          this.loadData();
          this.selectedInvoices = [];

          window.open(res.data.data.payment_url, "_blank");
        })
        .finally(() => {
          this.loadingMerge = false;
        })
    }
  }
}
</script>