<template>
  <div class="page-header">
      <div class="page-title">
          <h3>{{ title }}</h3>
          <p v-if="subTitle"> {{ subTitle }}</p>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: {
      type: String,
      default: null
    }
  }
}
</script>